import './App.css';
import Header from "./header/Header";
import Home from "./home/Home";
import {
    Routes,
    Route, BrowserRouter
} from 'react-router-dom';
import About from "./about/About";
import Career from "./career/Career";
import ContactUs from "./contactUs/ContactUs";
import Footer from "./footer/Footer";
import Address from "./contactUs/Address";
import CurrentOpenings from "./career/CurrentOpenings";
import WhyUs from './about/WhyUs';
import WhoWeAre from './about/WhoWeAre';
import MobileDevelopment from './services/MobileDevelopment';
import ApplicationDevelopment from './services/ApplicationDevelopment';
import CloudDevelopment from './services/CloudDevelopment';
import SecurityServices from './services/SecurityServices';
import InternetOfthings from './services/InternetOfthings';
import BlockChain from './services/BlockChain';
import QualityAssurance from './services/QualityAssurance';



function App() {
    return (
        <div className="App">
            <BrowserRouter>
                <Header/>
                <Routes>
                    <Route path='/' element={<Home/>} />
                    <Route path='/about' element={<About/>} />
                    <Route path='/services/application-development' element={<ApplicationDevelopment/>} />
                    <Route path='/services/mobile-development' element={<MobileDevelopment/>} />
                    <Route path='/services/cloud-development' element={<CloudDevelopment/>} />
                    <Route path='/services/security-services' element={<SecurityServices/>} />
                    <Route path='/services/internet-of-things' element={<InternetOfthings/>} />
                    <Route path='/services/blockchain' element={<BlockChain/>} />
                    <Route path='/services/quality-and-assurance' element={<QualityAssurance/>} />
                    <Route path='/career' element={<Career/>} />
                    <Route path='/contactus' element={<ContactUs/>} />
                    <Route path='/contactus/address' element={<Address />} />
                    <Route path='/about/why-us' element={<WhyUs/>} />
                    <Route path='/about/who-we-are' element={<WhoWeAre/>} />
                    {/* <Route path='/career/why-Modulobytes' element={<WhyModulobytes/>} /> */}

                    <Route path='/career/current-openings' element={<CurrentOpenings />} />
                </Routes>
                <Footer/>
            </BrowserRouter>
        </div>
    );
}

export default App;
