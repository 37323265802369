import React from 'react';
import './about.css'

function WhoWeAre() {
    return (
        <div className='whoWeare_container text-grey-400 justify-center max-w-2xl items-center m-auto font text-2xl'>
            <p className='whoWeare my-11'>
                We are delivery focused technology experts,
                who can reshape your business process in such a way that
                you can extract more from your existing resources and perform
                better in the markets than your competition.
                We know the challenges to handle ever changing
                technology with existing business in action. Our teams at
                Modulobytes possesses skills which can be a game changer for
                any business and harness the potential of your organisation.
                Here the teams are work on their values with higher ethics, integrity
                and professionalism, we are totally committed to your success.
                We believe in corporate symbiosis and wants to see our clients
                succeed the goals with whatever they want to. We are dedicated to
                make you better in your technological advancement which eventually
                benefits your clients and customers.
            </p>          
        </div>
    );
}

export default WhoWeAre;